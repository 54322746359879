

























































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {baseURL,instance} from '../../api/httpRequest';
import {
  pageOrder,
  pageOrderDetail,
  attachmentsList,
  deleteAttachments
} from "@/api";


interface IOrder {
  id: string;
  orderTime: string;
  payTime: string;
  payStatus: string;
  amount: number;
  price: string;
  outMsg:string;
  payType:string;
  payUrl:string;
  moneyType:string;
}

interface IOrderDetail {
  id: string;
  orderId: string;
  partnumber: string;
  leadtime: string;
  mft: number;
  qty: string;
  unitPrice:string;
  totalPrice:string;
  amount: string;
  auditStatus:string;
  auditMsg:string;
  outMsg:string;
  inMsg: string;
  receiverName:string;
  receiverMobile:string;
  receiverEmail:string;
  receiverPhone:string;
  receiverQq:string;
  receiverAddress: string;
  logisticsNo:string;
  deliverTime:string;
}

interface IVoucher {
  id: string;
  fileName: string;
  fileNameAlias: string;
  fileType: string;
  moduleId: number;
  relationId: string;
  uploadDate:string;
  uploadUser:string;
  uploadUserId:string;
}
@Component
export default class UserInfo extends Vue {
  //主订单
  currentPage = 1;
  pageSize = 10;
  total = 0;
  orderList: Array<IOrder> = [];
  //明细订单
  dialogDetailVisible = false;
  detailCurrentPage = 1;
  detailPageSize = 10;
  detailTotal = 0;
  orderDetailList: Array<IOrderDetail> = [];  
    //提交凭证
  dialogVoucherVisible = false;
  voucherCurrentPage = 1;
  voucherPageSize = 10;
  voucherTotal = 0;
  voucherList : Array<IVoucher> = [];
  currentOrderMainId  = "0";
  myHeaders={Authorization: window.localStorage.getItem('Authorization')}

  salerQq = window.localStorage.getItem("salerQq");

  mounted(): void {
    this.getDataList();
  }

  // fetchOrderList(): void {
  //   for (let i = 1; i < 10; i++) {
  //     this.orderList.push({
  //       id: i,
  //       orderId: "1394581779131928578",
  //       buyingTime: "2021-03-12 15:11:44",
  //       model: "2021-03-12 15:11:44",
  //       factory: "交易关闭",
  //       amount: 1011111,
  //       price: "¥16000",
  //       total: "¥2012312.55",
  //     });
  //   }
  // }


  checkTransportation(a: number, b: IOrder): void {
    console.log(a, b);
  }

  applyInvoice(a: number, b: IOrder): void {
    console.log(a, b);
  }

  changeProduct(a: number, b: IOrder): void {
    console.log(a, b);
  }

  handleSizeChange(val: number): void {
    this.pageSize = val;
    this.currentPage = 1;
    this.getDataList();
  }

  handleDetailSizeChange(val: number): void {
    this.detailPageSize = val;
    this.detailCurrentPage = 1;
    this.getDetailDataList("");
  }

  
  handleVoucherSizeChange(val: number): void {
    this.detailPageSize = val;
    this.detailCurrentPage = 1;
    this.getVoucherDataList("");
  }

  handleCurrentChange(val: number): void {
    this.currentPage = val;
    this.getDataList();
  }

  handleDetailCurrentChange(val: number): void {
    this.detailPageSize = val;
    this.getDetailDataList("");
  }

  handleVoucherCurrentChange(val: number): void {
    this.detailPageSize = val;
    this.getVoucherDataList("");
  }
  payNow(item : IOrder):void{
    
    let price :string = item.price;
    let codeUrl :string = item.payUrl;
    let orderNo :string = item.id;
    let payType :string = item.payType;
    if(payType == "0"){
     
              
      const div = document.createElement("div"); 
      div.innerHTML = codeUrl; 
      div.setAttribute("style", "display:none;");
      document.body.appendChild(div);
      document.forms[1].submit();
    }else if(payType == "1"){
      this.$router.push({ path: '/wxPay', query: { orderNo : `${orderNo}` ,price : `${price}`,codeUrl : `${codeUrl}`}});
    }else{
      this.$message.warning("不支持的交易方式");
    }
  }
  downloadVoucher(item :IVoucher){
      instance.get( baseURL + "offer/attachments/downloadfile?moduleId=2088&realFileName="+item.fileNameAlias+"&fileNameUrl="+item.fileName, {
        responseType:'arraybuffer',
        xsrfHeaderName:'Authorization',
        headers:{
          'Content-Type':'application/json',
          'Authorization':this.myHeaders
        }
      }).then(res => {
        const blob = new Blob([res.data],{
          type:'application/'+item.fileType
        })
        const objectUrl = URL.createObjectURL(blob)
        // 组装a标签
        let elink = document.createElement("a");
        // 设置下载文件名
        elink.download = item.fileName;
        elink.style.display = "none";
        elink.href = objectUrl;
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
        // window.location.href = objectUrl
      }).catch(err => {
        console.log(err)
      })

  }
    //订单分页
  getDataList(): void {
    let param = {
        "currentPage": this.currentPage,
        "pageSize":this.pageSize
    }
    pageOrder(param)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res.data)
            this.total = res.data.data.total;
            this.orderList = res.data.data.records;
            // this.addressList = res.data.data;
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
   //凭证信息分页
  getVoucherDataList(orderMainId :string): void {
    if("" == orderMainId){
      orderMainId = this.currentOrderMainId;
    }
    this.currentOrderMainId = orderMainId;
    this.dialogVoucherVisible = true;
    let param = {
        "currentPage": this.voucherCurrentPage,
        "pageSize":this.voucherPageSize,
    }
    attachmentsList(param,orderMainId,"2088")
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res.data)
            this.voucherTotal = res.data.data.total;
            this.voucherList = res.data.data.records;
            // this.addressList = res.data.data;
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
   //订单删除交易凭证
  deleteAttachments(id :number): void {

    this.$confirm("删除后不可恢复,确定进行删除操作吗 ?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            ///////删除请求
              deleteAttachments(id)
                .then((res) => {
                  
                  if (res.status == 200) {
                    if (res.data.code == 200) {
                      this.$message.success("删除成功");
                      this.getVoucherDataList("");
                      // this.userInfo = res.data.user
                      // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  } else {
                    this.$message.error(res.data.statusText);
                  }
                })
                .catch(() => {
                  console.log(" error ");
                });
          })
          ///////删除请求
          .catch(() => {console.log(" error ");});
  }
  
 //订单明细分页
  getDetailDataList(orderMainId :string): void {
    if("" == orderMainId){
      orderMainId = this.currentOrderMainId;
    }
    this.currentOrderMainId = orderMainId;
    this.dialogDetailVisible = true;
    let param = {
        "currentPage": this.detailCurrentPage,
        "pageSize":this.detailPageSize,
        "orderMainId": orderMainId
    }
    pageOrderDetail(param)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res.data)
            this.detailTotal = res.data.data.total;
            this.orderDetailList = res.data.data.records;
            // this.addressList = res.data.data;
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  
  payStatusFormatter(row :IOrder, column :number) {
    if(row.payStatus == "0"){
      return "待支付";
    }else if(row.payStatus == "1"){
      return "已支付";
    }else if(row.payStatus == "2"){
      return "已关闭";
    }else {
      return "其他"
    }
        
  }
  payTypeFormatter(row :IOrder, column :number) {
    if(row.payType == "0"){
      return "支付宝";
    }else if(row.payType == "1"){
      return "微信";
    }else if(row.payType == "2"){
      return "银联";
    }else if(row.payType == "3"){
      return "银行转账";
    }else {
      return "其他"
    }  
  }
  auditStatusFormatter(row :IOrderDetail, column :number) {
    if(row.auditStatus == "0"){
      return "已保存";
    }else if(row.auditStatus == "1"){
      return "已提交";
    }else if(row.auditStatus == "2"){
      return "执行中";
    }else if(row.auditStatus == "3"){
      return "已完成";
    }else if(row.auditStatus == "4"){
      return "合同终止";
    }else {
      return "其他"
    }  
  }
  totalPriceFormatter(row :IOrder, column :number) {
    if(row.moneyType == "1"){
      return "￥"+row.price ;
    }else if(row.moneyType == "2"){
      return "$"+row.price;
    }else {
      return row.price
    }  
  }
  actionUrl(){
    //+'&name='+this.userInfo.userId + "_" + this.pwCustomer.fullName
    return baseURL+'offer/hxwOrder/uploadAttach?ajax=1&moduleId=2088&id='+this.currentOrderMainId ;
  }
  beforeUpload(file:any){
      const isLtSize = file.size / 1024 / 1024 < 10;
      if (!isLtSize) {
        this.$message.error('上传文件大小不能超过 10MB!');
        return false;
      } 
  };
  uploadSuccess(response:any, file :any , businessLicenseList :any) {
  console.log(response,"11");
    if(response.code == 200){
      this.$message.success("上传成功");
      this.getVoucherDataList("");
      // this.pwCustomer.id = response.data.id
    }else{
      this.$message.error("上传失败");
    }
  };
  uploadError(err : any, file :any, businessLicenseList :any) {
     console.log(err,"33",file,"44",businessLicenseList);
  };
}
